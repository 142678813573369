import React, { useState, useEffect } from 'react';

function App() {
  const [selectedMunicipalityCode, setMunicipalityCode] = useState(-1);

  function handleMunicipalityChange(code) {
    setMunicipalityCode(code);
  }

  return <div>
    <p>Behöver du tillstånd för att hantera brandfarliga och explosiva varor? Vi hjälper dig att ansöka om tillstånd hos din lokala räddningstjänst.</p>
    <p>Välj din kommun nedan så får du instruktioner om hur du ska gå till väga.</p>
    <MunicipalitySelector onMunicipalityChange={handleMunicipalityChange} />
    <MunicipalityInformation code={selectedMunicipalityCode} />
  </div>;
}

function MunicipalityInformation(props)
{
  const [firedepartmentInfo, setData] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    (async function () {
      if (props.code !== -1) {
        var fireDepartment = await (await fetch('/api/GetFireDepartment?municipalityCode=' + props.code)).json();
        setData(fireDepartment);

        if(!fireDepartment){
          setShowInfo(false);
          setNotFound(true);
        }
        else{
          setShowInfo(true);
          setNotFound(false);
        }
        
      }
    })();
  },[props.code]);

  return  <div>{showInfo && <div><p>Du är verksam i {firedepartmentInfo.municipalityName} och ska därför söka tillstånd hos {firedepartmentInfo.fireDepartmentName}.</p>
  <p>Klicka <a href={firedepartmentInfo.pageLink} target="_blank" rel="noopener noreferrer">här</a> för att komma till räddningstjänstens sida där du hittar info om ansökan.</p>
  {firedepartmentInfo.formLink && <p>Klicka <a href={firedepartmentInfo.formLink} target="_blank" rel="noopener noreferrer">här</a> för att komma till ansökningsformuläret.</p>}
  {firedepartmentInfo.digital && <p>Denna räddningstjänst erbjuder en digital tjänst där du kan ansöka.</p>}
  </div>
  }<div>
    {notFound && <p>Tyvärr finns ingen info för den valda kommunen.</p>}
  </div>
  </div>
}

function MunicipalitySelector(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async function () {
      var municipalities = await (await fetch('/api/GetMunicipalities')).json();
      setData(municipalities);
    })();
  }, []);

  function handleChange(e) {
    var code = data[e.target.selectedIndex - 1].code;

    props.onMunicipalityChange(code);
  }

  return <div>
    <select className="form-control" onChange={e => handleChange(e)}>
      <option>Välj kommun</option>
      {data.map((i, h) => (
        <option key={i.code} value={i.name}>
          {i.name}
        </option>
      ))}
    </select>
  </div>;
}

export default App;
